<template>
  <UCard
    class="ring-0 shadow-none flex-1 flex flex-col min-w-1 min-h-1"
    :ui="{
      body: {
        base: 'overflow-auto min-h-96 scroll-stable',
        padding: '!p-0',
      },
    }"
  >
    <template #header>
      <BoardSettingsHeader
        hash="settings-automations"
        title="Automations"
        :last-page="lastPage"
      />
    </template>
    <div class="flex">
      <div
        v-for="tab in tabs"
        :key="tab.name"
        :class="[
          'py-3 px-6 w-fit font-medium cursor-pointer text-sm text-gray-500 border-b',
          { 'text-primary-500 border-primary-500': tab.name === sideBarMode }
        ]"
        @click="sideBarMode = tab.name"
      >
        {{ tab.label }}
      </div>
      <div class="border-b grow"></div>
    </div>
    <div v-if="sideBarMode" class="p-6">
      <component :is="activeComponent" :source="source" :parent-id="parentId" />
    </div>
  </UCard>
</template>

<script lang="ts" setup>
import type { AutomationRule, AutomationRuleFilter } from '#automation/types'

const props = defineProps<{
  parentId: string
  source: MorphSource
}>()

const route = useRoute()

const { sideBarMode, currentRuleId, currentRule, allRules } = useAutomationSharedState()
const { result } = useAutomationRulesQuery({
  parentId: props.parentId || route.query.id as string,
  source: props.source,
} as AutomationRuleFilter)

const tabs = [
  {
    label: 'Rules',
    name: 'rules',
  },
  {
    label: 'Custom buttons',
    name: 'custom-buttons',
  },
  {
    label: 'Templates',
    name: 'templates',
  }]

const activeComponent = computed(() => {
  switch (sideBarMode.value) {
    case 'rules':
      return defineAsyncComponent(() => import('./AutomationRules.vue'))
    case 'custom-buttons':
      return defineAsyncComponent(() => import('./AutomationCustomButtons.vue'))
    case 'templates':
      return defineAsyncComponent(() => import('./AutomationTemplates.vue'))
    default:
      return defineAsyncComponent(() => import('./AutomationRules.vue'))
  }
})

const lastPage = computed(() =>
  props.source === 'settings-pack' ? 'settingsPack' : 'index'
)

onMounted(() => {
  currentRuleId.value = ''
  currentRule.value = {} as AutomationRule
})

watch(() => result.value,
  () => {
    if (result.value?.automationRules) {
      allRules.value = result.value?.automationRules || []
    }
  })
</script>
